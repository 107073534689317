import React from "react";
import logo from "./image/logo.png"
import "./Home.css"
export const Navbar = () => {
  const handleDownloadClick = async () => {
    // First fetch to get the public IP
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
      const dataip = data.ip;
        console.log('Your IP Address is:', dataip);
        // Get the promocode from the URL
        const url = window.location.href;
        const urlParams = new URLSearchParams(window.location.search);
        // Construct the URL with the referral code
        // Extract the code after '?' if present
        const queryString = window.location.search; // Get the query string part
        const promocode = queryString ? queryString.substring(1) : ''; // Extract substring after '?', remove '?'
          console.log('Promocode:', promocode);
          // Prepare the data for the POST request
        const postData = new URLSearchParams();
        postData.append('ip', dataip);
        postData.append('promocode', promocode);
        // Now send the POST request after IP is fetched
        fetch('https://teenpattijackpot.online/api/getipaddress.php', {
           method: 'POST',
           headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
           },
           body: postData // The data to send
         })
         .then(response => response.json()) // Parse JSON response
         .then(result => {
           console.log('Success:', result);
         })
         .catch(error => {
           console.error('Error in POST request:', error);
         });
       })
       .catch(error => {
         console.error('Error fetching the IP address:', error);
       });
       // Log browser and network details
       console.log("User Agent:", navigator.userAgent);
       console.log("Language:", navigator.language);
       console.log("Screen Resolution:", window.screen.width + 'x' + window.screen.height);
       console.log("Viewport Size:", window.innerWidth + 'x' + window.innerHeight);
       console.log("Platform:", navigator.platform);
       // Set a cookie with a path for your web app (not a file path)
       document.cookie = "referralCode=12345; path=/";
       console.log("Cookies after setting:", document.cookie);  
       // Set additional cookie attributes like expiry
       document.cookie = "referralCode=12345; path=/; max-age=3600"; // Expires in 1 hour
       console.log("Cookies after expiry setting:", document.cookie);
      // Trigger APK download
      const apkUrl = process.env.PUBLIC_URL + '/TeenpattiJackpot.apk'; // Replace with your APK URL
      const link = document.createElement('a');
      link.href = apkUrl;
      link.download = 'TeenpattiJackpot.apk'; // Optional: Set the filename for the downloaded APK
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   };
  return (
    <div>
      <nav class="navbar  fixed-top" style={{background:'#fcf6d5',height:'10vh'}}>
        <div class="container-fluid" style={{display: 'flex',flexDirection:'row',justifyContent:'space-between',alignItems:'center'}}>
          <img class="logoImage" src={logo} alt="logo"/>
          <button style={{backgroundColor:'#bc3554',borderColor:'#bc3554',borderRadius:'4px',border:'none',fontSize:'14px',padding: '8px 15px',color:'#fcf6d5'}} onClick={handleDownloadClick} download>DOWNLOAD APK</button>
        </div>
      </nav>
    </div>
  );
};
