import React from "react";
import "../Component/Home.css";
import step1 from "../Component/image/step1.png";
import step2 from "../Component/image/step2.png";
import step3 from "../Component/image/step3.png";
import game1 from '../Component/image/7 up Down.png';
import game2 from '../Component/image/Ander Bahar Go.png'
import game3 from '../Component/image/Avitor.png'
import game4 from '../Component/image/Baccarat.png'
import game5 from '../Component/image/Car Roullete.png'
import game6 from '../Component/image/Dragon and tiger.png'
import game7 from '../Component/image/7 Slot.jpg.png'
import video2 from '../Component/image/dragonVStiger.mp4'
import video3 from '../Component/image/carroulette.mp4'
import video4 from '../Component/image/teenpatti.mp4'
import video5 from '../Component/image/7updown.mp4'
import { SiPaytm } from "react-icons/si";
import { FaCcPaypal } from "react-icons/fa6";
import { RiVisaLine } from "react-icons/ri";
import security from '../Component/image/100secured.webp'
import { FaInstagram } from "react-icons/fa6";
import { LuYoutube } from "react-icons/lu";
import { AiOutlineFacebook } from "react-icons/ai";
import { FaTelegram } from "react-icons/fa6";
import { Link } from "react-router-dom";

export const Home = () => {
  const handleDownloadClick = async () => {
    // First fetch to get the public IP
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
      const dataip = data.ip;
        console.log('Your IP Address is:', dataip);
        // Get the promocode from the URL
        const url = window.location.href;
        const urlParams = new URLSearchParams(window.location.search);
        // Construct the URL with the referral code
        // Extract the code after '?' if present
        const queryString = window.location.search; // Get the query string part
        const promocode = queryString ? queryString.substring(1) : ''; // Extract substring after '?', remove '?'
          console.log('Promocode:', promocode);
          // Prepare the data for the POST request
        const postData = new URLSearchParams();
        postData.append('ip', dataip);
        postData.append('promocode', promocode);
        // Now send the POST request after IP is fetched
        fetch('https://teenpattijackpot.online/api/getipaddress.php', {
           method: 'POST',
           headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
           },
           body: postData // The data to send
         })
         .then(response => response.json()) // Parse JSON response
         .then(result => {
           console.log('Success:', result);
         })
         .catch(error => {
           console.error('Error in POST request:', error);
         });
       })
       .catch(error => {
         console.error('Error fetching the IP address:', error);
       });
       // Log browser and network details
       console.log("User Agent:", navigator.userAgent);
       console.log("Language:", navigator.language);
       console.log("Screen Resolution:", window.screen.width + 'x' + window.screen.height);
       console.log("Viewport Size:", window.innerWidth + 'x' + window.innerHeight);
       console.log("Platform:", navigator.platform);
       // Set a cookie with a path for your web app (not a file path)
       document.cookie = "referralCode=12345; path=/";
       console.log("Cookies after setting:", document.cookie);  
       // Set additional cookie attributes like expiry
       document.cookie = "referralCode=12345; path=/; max-age=3600"; // Expires in 1 hour
       console.log("Cookies after expiry setting:", document.cookie);
      // Trigger APK download
      const apkUrl = process.env.PUBLIC_URL + '/TeenpattiJackpot.apk'; // Replace with your APK URL
      const link = document.createElement('a');
      link.href = apkUrl;
      link.download = 'TeenpattiJackpot.apk'; // Optional: Set the filename for the downloaded APK
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   };
  return (
   <div style={{ marginTop: "40px" }}>
      <div className="bannerCont">
        <div className="bannerCont1">
          <h4>Get up to</h4>
          <h1>₹100 WELCOME BONUS</h1>
          <button onClick={handleDownloadClick} download>
            JOIN
          </button>
        </div>
      </div>
      {/* //topgames */}
     <div className="topGameCont">
       <div className="text-center topGameContHead">
          <p>Top Games</p>
       </div>
       <div class="scroll-container">
         <div class="content">
           <div className="topContMain">
             <img src={game1} alt=""/>
             <h6>7 Up Down</h6>
           </div>
           <div className="topContMain">
              <img src={game2} alt=""/>
              <h6>Andar Bahar</h6>
           </div>
           <div className="topContMain">
              <img src={game3} alt=""/>
              <h6>Aviator</h6>
           </div>
           <div className="topContMain">
              <img src={game4} alt=""/>
              <h6>Baccarat</h6>
           </div>
           <div className="topContMain">
             <img src={game5}  alt=""/>
             <h6>Car Roullete</h6>
           </div>
           <div className="topContMain">
             <img src={game6} alt=""/>
             <h6>Dragon VS Tiger</h6>
           </div>
           <div className="topContMain">
             <img src={game7} alt=""/>
             <h6>Seven Slot</h6>
           </div>
          </div>
       </div>
     </div>
      {/* //ourexclusivetable */}
     <div className="ourExc">
       <div className="text-center ourExcContent">
          <p>Our Exlusive Tables</p>
       </div>
       <div class="scroll-container">
         <div class="content2">
           <div className="exclusiveCotnent">
              <video  width="100%" style={{ height: 'auto' }} loop autoPlay muted>
                    <source src={video5} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
              <h5  className="txtExc"  style={{ color: "#bc3554", fontWeight: "bold" }}>7 Up Down</h5>
            </div>
            <div className="exclusiveCotnent">
              <video  width="100%" style={{ height: 'auto' }} loop autoPlay muted>
                    <source src={video2} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
              <h5  className="txtExc"  style={{ color: "#bc3554", fontWeight: "bold" }}>Dragon Vs Tiger</h5>
            </div>
            <div className="exclusiveCotnent">
            <video  width="100%" style={{ height: 'auto' }} loop autoPlay muted>
                    <source src={video3} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
              <h5 className="txtExc"  style={{ color: "#bc3554", fontWeight: "bold" }}>Car Roullete</h5>
            </div>
            <div className="exclusiveCotnent">
            <video width="100%" style={{ height: 'auto' }} loop autoPlay muted>
                    <source src={video4} type="video/mp4" />
                    Sorry, your browser doesn't support embedded videos.
                </video>
              <h5 className="txtExc"  style={{ color: "#bc3554", fontWeight: "bold" }}>Teenpatti</h5>
            </div>
         </div>
       </div>
     </div>
     {/* //baner */}
     <div className="bgRowCont">
       <div className="bgRowCont2">
         <div className="bgRowCont3">
           <h1 style={{color:'#fcf6d5'}}>Play Top Games</h1>
         </div>
         <div className="bgRowCont4">
           <button onClick={handleDownloadClick} download>PLAY</button>
         </div>
       </div>
     </div>
     {/* //steps */}
     <div className="stepCont">
        <div className="text-center stepCont2">
          <p>Join in 3 Easy Steps</p>
        </div>
       <div className="stepContBox">
          <div className="stepContBox1">
            <img src={step1} alt=""/>
            <h4>JOIN</h4>
            <p>Quick & Easy</p>
          </div>
          <div className="stepContBox1">
            <img src={step2} alt=""/>
            <h4>CLAIM</h4>
            <p>Your Bonus</p>
          </div>
          <div className="stepContBox1">
            <img src={step3} alt=""/>
            <h4>PLAY</h4>
            <p>Play & Win</p>
          </div>
        </div>
     </div>
     {/* //paymentmethods */}
     <div className="topPayment">
       <div className="text-center topPaymentHead">
          <p>Top Secure Payment Methods</p>
       </div>
       <div className="text-center topPaymentCont">
         <FaCcPaypal className="iconTxt"/>
         <SiPaytm  className="iconTxt"/>
         <RiVisaLine className="iconTxt"/>
       </div>
     </div>
     {/* //footer */}
     <div className="safeMainCont">
       <div  className="safeMainCont2">
         <p>Safe, Secure & Award-Winning</p>
         <img src={security} alt=""/>
       </div>
     </div> 
     <div className="onlineCasiCont">
       <div className="onlineCasi2">
         <h4>Teenpatti Jackpot</h4>
         <p>Teenpatti Jackpot have skyrocketed in popularity recently, offering players a convenient and accessible way to indulge in their favorite casino games from anywhere in the world. With just a few clicks, you can experience the thrill and excitement of the casino floor without ever leaving your home. Whether you're a fan of slots, poker, blackjack, or roulette, online casinos bring the action to your fingertips, allowing you to play anytime, anywhere.</p>
         <div className="onlineCasi3">
           <p style={{borderRight:'1px solid #826458',width:'6%',color:'#826458'}}><Link to='/' style={{textDecoration:'none',color:'#826458'}}>Home</Link></p>
           <p style={{borderRight:'1px solid #826458',width:'8%',color:'#826458'}}><Link to='/about' style={{textDecoration:'none',color:'#826458'}}>About us</Link></p>
           <p style={{borderRight:'1px solid #826458',width:'14%',color:'#826458'}}><Link to='/terms' style={{textDecoration:'none',color:'#826458'}}>Terms & Conditions</Link></p>
           <p style={{borderRight:'1px solid #826458',width:'10%',color:'#826458'}}><Link to='/privacy' style={{textDecoration:'none',color:'#826458'}}>Privacy Policy</Link></p>
           <p style={{borderRight:'1px solid #826458',width:'23%',color:'#826458'}}><Link to='/cancell' style={{textDecoration:'none',color:'#826458'}}>Cancellation and Refund Policy</Link></p>
           <p style={{borderRight:'1px solid #826458',width:'8%',color:'#826458'}}><Link to='/pricing' style={{textDecoration:'none',color:'#826458'}}>Pricing</Link></p>
           <p style={{width:'10%',color:'#826458',}}><Link to='/cont' style={{textDecoration:'none',color:'#826458'}}>Contact Us</Link></p>
         </div>
         <div className="onlineCasi3A">
           <div style={{height:'6vh',width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
             <p style={{borderRight:'1px solid #826458',width:'20%',color:'#826458'}}><Link to='/' style={{textDecoration:'none',color:'#826458'}}>Home</Link></p>
             <p style={{borderRight:'1px solid #826458',width:'24%',color:'#826458'}}><Link to='/about' style={{textDecoration:'none',color:'#826458'}}>About us</Link></p>
             <p style={{width:'18%',color:'#826458'}}><Link to='/pricing' style={{textDecoration:'none',color:'#826458'}}>Pricing</Link></p>
           </div>
           <div style={{height:'6vh',width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
              <p style={{borderRight:'1px solid #826458',width:'45%',color:'#826458'}}><Link to='/terms' style={{textDecoration:'none',color:'#826458'}}>Terms & Conditions</Link></p>
              <p style={{width:'30%',color:'#826458'}}><Link to='/privacy' style={{textDecoration:'none',color:'#826458'}}>Privacy Policy</Link></p>
           </div>
           <div style={{height:'6vh',width:'100%',display:'flex',flexDirection:'row',justifyContent:'space-around',alignItems:'center'}}>
              <p style={{width:'70%',color:'#826458'}}><Link to='/cancell' style={{textDecoration:'none',color:'#826458'}}>Cancellation and Refund Policy</Link></p>
           </div>
         </div>
       </div>
     </div> 
     <div className="copyCont">
       <div className="copyCont1">Copyright 2023 teenpattijackpot.online All rights reserved</div>
       <div className="copyCont2">
          <FaInstagram className="textCopy"/>
          <LuYoutube className="textCopy"/>
          <AiOutlineFacebook className="textCopy"/>
          <FaTelegram className="textCopy"/>
       </div>
     </div>
   </div>
  );
};
